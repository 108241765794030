import type { ProfileDto } from '../common/dtos';
import { FetchError } from 'ofetch';

interface ProfileState {
  preview: string;
  loading: boolean;
  allTokens: string[];
  isVerifyPhoneToastVisible: boolean;
}

export const useProfileStore = defineStore('profileStore', {
  state: (): ProfileState => ({
    preview: '',
    loading: false,
    allTokens: [],
    isVerifyPhoneToastVisible: false,
  }),
  actions: {
    async updateProfileImage(firstName: string, lastName: string, businessName: string, preview: string, file: File) {
      const { $snapApi } = useNuxtApp();

      const rawData = {
        firstName,
        lastName,
        businessName,
      };

      const rawDataJSON = JSON.stringify(rawData);

      const formData = new FormData();

      if (file) {
        formData.append('image', file, file.name);
      }

      formData.append('data', rawDataJSON);

      this.loading = true;

      await $snapApi({
        method: 'put',
        url: 'profile',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.loading = false;
      this.preview = preview || '/images/avatar.png';
    },
    async createNewKey(name: string, isPrimary: boolean) {
      const { $snapApi } = useNuxtApp();

      await $snapApi({
        method: 'post',
        url: 'client-api-key/create-new',
        data: {
          name,
          isPrimary,
        },
      });
    },
    async updateKey(id: number, name: string, isPrimary: boolean) {
      const { $snapApi } = useNuxtApp();

      await $snapApi({
        method: 'post',
        url: 'client-api-key/update',
        data: {
          id,
          name,
          isPrimary,
        },
      });
    },
    async createProfile(user: ProfileDto, isAgent: boolean, isSender: boolean = false) {
      const { $snapApi } = useNuxtApp();
      this.loading = true;

      await $snapApi({
        method: 'post',
        url: `profile${isSender ? '/sender' : ''}?isAgentSnap=${isAgent}`,
        data: user,
      });

      this.loading = false;
    },
    async authorizeUser(credentials: Pick<ProfileDto, 'email' | 'password'>) {
      const { token, data } = useAuthState();
      const { signIn } = useAuth();
      const { $toastError } = useNuxtApp();

      this.loading = true;

      try {
        await signIn(
          {
            email: credentials.email,
            password: credentials.password,
          },
          { callbackUrl: '/' },
        );
        if (token.value && data.value) {
          localStorage.setItem('_snap_token', token.value.split(' ')[1]);
          localStorage.setItem('_profile_uid', data.value.id.toString());
        }
      } catch (error) {
        if (error instanceof FetchError) {
          $toastError(error.data.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async resetPassword(token: string, password: string) {
      const { $snapApi } = useNuxtApp();

      this.loading = true;
      const data = {
        token,
        newPassword: password,
      };
      await $snapApi({ method: 'post', url: '/unsecured/reset-password', data });
      this.loading = false;
    },
    async updateNotifyEmail(isAllowed: boolean) {
      const { $snapApi, $toastSuccess } = useNuxtApp();

      this.loading = true;
      try {
        await $snapApi({ method: 'put', url: 'profile/payment-email-preference', data: { isAllowed } });

        $toastSuccess('Notification email preference updated successfully');
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async updateMarketingEmail(isAllowed: boolean) {
      const { $snapApi, $toastSuccess } = useNuxtApp();

      this.loading = true;
      try {
        await $snapApi({ method: 'put', url: 'profile/marketing-email-preference', data: { isAllowed } });

        $toastSuccess('Marketing email preference updated successfully');
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async updateNotifyText(isAllowed: boolean) {
      const { $snapApi, $toastSuccess } = useNuxtApp();

      this.loading = true;
      try {
        await $snapApi({ method: 'put', url: 'profile/payment-sms-preference', data: { isAllowed } });

        $toastSuccess('SMS preference updated successfully');
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async sendVerificationCode() {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({ method: 'put', url: 'profile-info/send-verification-code' });
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async verifyPhone(phoneCode: string) {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({ method: 'put', url: 'profile-info/verify-phone', data: { phoneCode } });
      } finally {
        this.loading = false;
      }
    },
    async updatePhone(phone: string) {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      try {
        await $snapApi({ method: 'put', url: 'profile-info/update-phone', data: { phone } });
      } finally {
        this.loading = false;
      }
    },
    async signOut() {
      const { signOut } = useAuth();

      localStorage.removeItem('_snap_token');
      localStorage.removeItem('_profile_uid');

      this.loading = true;

      try {
        await signOut({ callbackUrl: '/login' });
      } catch {
      } finally {
        this.loading = false;
      }
    },
  },
});
